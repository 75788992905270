<template>
  <div class="expert_registration">
    <div v-if="showInviter" class="inviter">
      <div class="avatar">
        <img :src=inviter.avatar>
      </div>
      <div class="userInfo">
        <span>{{this.inviter.nickName}}&nbsp;&nbsp;邀请你入驻</span>
      </div>
    </div>
    <div style="margin-top: 100px;">
      <h2 style="text-align: left;margin-left: 20px">外卖推广达人</h2>
    </div>
    <form @submit.prevent="submitRegister" class="registerFrom">
      <div class="phone">
        <input class="input" id="phone" type="tel" pattern="^1\d{10}$" v-model="formData.phone" placeholder="请输入手机号" required>
      </div>

      <div class="verificationCodeInput">
        <input class="verificationCodeInput" type="tel" maxlength="6" v-model="formData.verificationCode" placeholder="请输入短信验证码" required>
        <button id="sendCode" class="sendCodeBtn" @click="sendVerificationCode">
          {{ clockShow ? clock + 's后重新获取验证码' : '获取验证码' }}
        </button>
      </div>

      <div class="term">
        <input class="checkbox" type="checkbox" @click="checkTerm" id="term" name="term">
        <span class="message">我已阅读并同意
          <a href="/agreement?fileName=ExpertAccountServiceAgreement">《达人账户服务协议》</a> 和
          <a href="/agreement?fileName=ExpertAccountRules">《达人账户规则》</a>
        </span>
      </div>
      <center>
        <button class="submitBtn" type="submit">注册达人</button>
      </center>
    </form>
  </div>
</template>

<style>
body {
  background-color: white!important;
}

.expert_registration .registerFrom {
  padding-left: 5px;
  padding-right: 5px;
}

.expert_registration .registerFrom input {
  border: 1px rgb(240, 240, 240) solid;
}
.expert_registration .registerFrom .phone {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.expert_registration .registerFrom .phone .input {
  font-size: 12px;
  height: 37px;
  width: 90%;
  padding: 5px;
}
.expert_registration input {
  background-color: #f0f0f0;
}

.expert_registration .registerFrom .verificationCodeInput {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
}
.expert_registration .registerFrom .verificationCodeInput input {
  width: 50%;
  height: 37px;
  padding: 5px;
  background-color: #f0f0f0;
}
.expert_registration input::placeholder {
  color: #c3c3c3;
}

.expert_registration .registerFrom .verificationCodeInput .sendCodeBtn {
  height: 37px;
  width: 40%;
  color: #a8a8a8;
  border: none;
  padding: 5px;
  background-color: #f0f0f0;
}

.expert_registration .registerFrom .term {
  margin-left: 5%;
  text-align: left;
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.expert_registration .registerFrom .message {
  font-size: 12px;
  color: #a3a3a3;
  padding-left: 5px;
  padding-top: 1px;
}
.expert_registration .registerFrom .message a {
  color: black;
}

.expert_registration .registerFrom .submitBtn {
  width: 90%;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  background-color: rgb(73, 101, 242);

  /*top: 70%;*/
  /*left: 10%;*/
  /*position: fixed;*/
}
</style>


<script>
import axios from "axios";

export default {
  name: "expert_registration",
  data () {
    return {
      scope: 'snsapi_userinfo',
      appId: this.$store.state.appId,
      appSecret: this.$store.state.appSecret,
      userId: 0,
      userInfo: this.$store.state.userInfo,
      redirectUri: this.$store.state.webHost + 'expert/registration',
      weChartDomain: this.$store.state.weChartDomain,
      formData: {
        phone: '',
        verificationCode: '',
      },
      clock: 60,
      showInviter: false,
      clockShow: false,
      inviterId: 0,
      inviter: null,
      codeUser: null
    }
  },
  methods : {
    submitRegister() {
      let term = document.getElementById('term');
      console.log(term.checked)
      if (!term.checked) {
        alert('请阅读并同意相关服务事项');
        return false;
      }

      let params = {
        userId: this.userInfo.id,
        phone: this.formData.phone,
        verifyCode: this.formData.verificationCode
      }

      if (this.inviterId > 0) {
        params = {
          userId: this.userInfo.id,
          phone: this.formData.phone,
          verifyCode: this.formData.verificationCode,
          commissionUserId: this.inviterId
        }
      }

      axios.post(this.$store.state.host + 'tt/wechat/user/modify', params).then((result) => {
        if (result.data.code !== '200') {
          alert(result.data.msg)
          return;
        }

        this.loginUser(this.userInfo.id);
        sessionStorage.setItem('new_user', true);
      }).catch(function (error) {
        alert('注册失败！');
        console.log(error)
      })
    },
    sendVerificationCode() {
      var phone = document.getElementById('phone');
      if (phone.value === "") {
        phone.setCustomValidity('请输入手机号')
        return;
      }

      const regex = /^[1][3-9]\d{9}$/; // 以1开头，第二位是3-9，后面跟着9位数字
      if (!regex.test(phone.value)) {
        phone.setCustomValidity('手机号不正确')
        return;
      }

      phone.setCustomValidity('')

      //发送短信验证码
      if (!this.clockShow) {
        axios.get(this.$store.state.host + 'tt/wechat/user/verify/code', {
          params: {
            phone: this.formData.phone
          }
        }).then((msg) => {
          //触发倒计时
          this.setTime()
          this.clockShow = true
          console.log(msg)
        }).catch(function (msg) {
          console.log(msg.data.msg)
        })
      }
    },
    setTime() {
      if (this.clock === 0) {
        this.clock = 60
        this.clockShow = false
        return
      } else {
        this.clock--
      }

      setTimeout(() => { this.setTime() }, 1000)
    },
    checkTerm() {
      let term = document.getElementById('term');
      if (term.checked) {
        term.setCustomValidity('');
      }
    },
    loginUser(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        sessionStorage.setItem('user', JSON.stringify(this.userInfo))

        if (userInfo.data.body.status === 1) {
          this.$router.push({name: 'personalCenter'})
          return;
        }

        this.setInviterId();
      }).catch(function (msg) {
        console.log(msg);
        alert('获取用户信息失败！')
        this.userInfo = null;
        sessionStorage.setItem('user', null)
      })
    },
    setInviterId() {
      this.inviterId = this.$route.query.state;
      if (this.inviterId > 0) {
        axios.get(this.$store.state.host + 'tt/wechat/user/info', {
          params: {
            userId: this.inviterId
          }
        }).then((inviter) => {
          this.showInviter = true;
          this.inviter = inviter.data.body;
        })
      }
    }
  },
  //开始加载
  created () {
    // 已登陆跳转
    let code = this.$route.query.code;
    if (!code) {
      let userId = this.$route.query.userId ? this.$route.query.userId : 0;
      window.location.href = this.weChartDomain + '/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + this.redirectUri + '&response_type=code&scope=' + this.scope + '&state=' + userId + '#wechat_redirect'
      return;
    }

    let user = JSON.parse(sessionStorage.getItem('user'));
    if (user !== null) {
      this.loginUser(user.id);
      return;
    }

    //登陆
    let state = this.$route.query.state ? this.$route.query.state : 0;
    axios.get(this.$store.state.host + 'tt/wechat/program/userinfo', {
      params: {
        code: code,
        state: state
      }
    }).then((userInfo) => {
      this.loginUser(userInfo.data.body.userId);
    }).catch(function (msg) {
      console.log(msg)
      alert('获取用户信息失败！')
    })
  },
  mounted() {
    document.title = '达人注册'; // 设置页面标题
  }
};
</script>
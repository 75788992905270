<template>
  <div class="customer">
    <div class="content" >
      <div class="logo">
        <img src="../../assets/pay-ok.png">
      </div>
      <div class="pay-title" style="font-size: 25px;font-weight: bold;">
        <span>支付成功</span>
      </div>
      <div class="qr">
        <img :src="imgSrc">
        <img v-if="imgSrc === null" src="../../assets/loading.gif">
        {{orderId}}
      </div>
      <div class="notification">
        <span style="color: #A1A1A1;">长按识别二维码可联系客服</span>
        <button @click="submitInfo" v-if="switchStatus" class="next-btn">提交商户资料</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));

    axios.get(this.$store.state.host + 'tt/wechat/common/merchant/setting').then((records) => {
      this.switchStatus = records.data.body.switchStatus;
    })

    if (sessionStorage.getItem('orderId') == null) {
      axios.get(this.$store.state.host + 'tt/wechat/order/service/order', {
        params: {
          userId: this.userInfo.id,
        }
      }).then((records) => {
        this.orderId = records.data.body.orderId;
      })
    } else {
      this.orderId = sessionStorage.getItem('orderId')
    }

    axios.get(this.$store.state.host + 'tt/wechat/common/config/images'
    ).then((result) => {
      let data = result.data.body;
      data.forEach(item => {
        if (item.name === "customer-qr") {
          this.imgSrc = this.$store.state.imgHost + item.value;
          console.log(this.imgSrc)
        }
      })
    })

  },
  methods: {
    submitInfo() {
      this.$router.push({'name': 'merchantStoreInfo'})
    }
  },
  data() {
    return {
      orderId: '',
      userInfo: null,
      switchStatus: false,
      imgSrc: null,
    }
  }
}
</script>

<style>
.customer .next-btn {
  margin-top: 10px;
  padding: 5px;
  background-color: #007BFF;
  color: white;
  border: 1px solid #007BFF;
  border-radius: 5px;
}
  .customer {
    background-image: url('../../assets/background.png');
    width: 100%;
    height: 750px;
    background-position: center;
    background-size: cover;
    padding-top: 200px;
  }

  .customer .content {
    color: black;
    font-size: 18px;
  }

  .customer .logo img {
    width: 60px;
    margin-bottom: 10px;
  }

  .customer .qr {
    display: grid;
    justify-content: center;
    margin-top: 30px;
  }
  .customer .qr img {
    width: 180px;
  }
  .customer .notification {
    justify-content: center;
    margin-top: 10px;
    display: grid;
  }
</style>
<template>
  <div class="settle-flow">
    <div v-if="showInviter" class="inviter">
      <div class="avatar">
        <img :src=inviter.avatar>
      </div>
      <div class="userInfo">
        <span>{{this.inviter.nickName}}&nbsp;&nbsp;邀请你入驻</span>
      </div>
    </div>
    <div style="height: 40px" v-if="showInviter"></div>
    <div class="content">
      <center>
        <button class="next-button" @click="next" type="button">
          <span style="font-size: 22px">立即入驻</span>
          <span class="btn-describe">全国不限城市，单店多店均可</span>
        </button>
      </center>
    </div>
  </div>
</template>

<style>
.settle-flow .inviter {
  position: fixed;
  width: 100%;
}

.settle-flow .content {
  background-image: url('../../assets/flow.jpeg');
  width: 100%;
  height: 1400px;
  background-position: center;
  background-size: cover;
}
.settle-flow .settle-flow-img img {
  width: 80%;
  margin-top: 30px;
}
.next-button .btn-describe {
  font-size: 11px;
  color: rgb(255, 255, 255, 0.8);
}

.next-button {
  top: 93%;
  height: 7%;
  width: 100%;
  position: fixed;
  display: grid;
  font-weight: bold;
  color: white;
  border: none;
  padding: 5px 10px 5px 10px;
  font-size: 18px;
  margin-bottom: 50px;
  background-color: rgb(73, 137, 242);
}

</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      scope: 'snsapi_userinfo',
      appId: this.$store.state.appId,
      redirectUri: this.$store.state.webHost,
      weChartDomain: this.$store.state.weChartDomain,
      inviterId: 0,
      userInfo: null,
      showInviter: false,
      redirectUrlName: 'businessPayment',
      inviter: null
    }
  },
  methods: {
    next() {
      if (this.userInfo === null) {
        return;
      }

      console.log(this.userInfo);
      if (this.userInfo !== null) {
        if (this.userInfo.payStatus === 1) {
          this.loginUser(this.userInfo.id);
          this.$router.push({name: 'customerView'})
          return;
        }

        if (this.userInfo.merchantStatus === 1) {
          this.loginUser(this.userInfo.id);
          if (this.inviterId > 0) {
            this.$router.push({name: 'businessPayment', query: {inviterId: this.inviterId}})
          } else {
            this.$router.push({name: 'businessPayment'})
          }
          return;
        }
      }

      this.$router.push({name: 'businessRegistration', query: {inviterId: this.inviterId}})
    },
    loginUser(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        this.setInviterId();
        sessionStorage.setItem('user', JSON.stringify(this.userInfo))
      }).catch(function (msg) {
        console.log(msg)
        alert('请求失败用户信息失败！')
      })
    },
    setInviterId() {
      if (this.userInfo == null) {
        return;
      }

      this.inviterId = this.$route.query.state;

      if (this.userInfo.payStatus === 1) {
        //展示已经绑定的邀请人
        if (this.userInfo.commissionUserId > 0) {
          this.inviterId = this.userInfo.commissionUserId;
        } else {
          return;
        }
      }

      if (this.userInfo.status === 1) {
        this.inviterId = this.userInfo.id;
        this.showInviter = true;
        this.inviter = this.userInfo;
        return;
      }

      if (this.inviterId > 0) {
        axios.get(this.$store.state.host + 'tt/wechat/user/info', {
          params: {
            userId: this.inviterId
          }
        }).then((inviter) => {
          this.showInviter = true;
          this.inviter = inviter.data.body;
        })
      }
    }
  },
  created () {
    let code = this.$route.query.code;
    if (!code) {
      let userId = this.$route.query.userId ? this.$route.query.userId : 0;
      window.location.href = this.weChartDomain + '/connect/oauth2/authorize?appid=' + this.appId + '&redirect_uri=' + this.redirectUri + '&response_type=code&scope=' + this.scope + '&state=' + userId + '#wechat_redirect'
      return;
    }

    let user = JSON.parse(sessionStorage.getItem('user'));
    if (user !== null) {
      this.loginUser(user.id);
      return;
    }

    //登陆
    let state = this.$route.query.state ? this.$route.query.state : 0;
    axios.get(this.$store.state.host + 'tt/wechat/program/userinfo', {
      params: {
        code: code,
        state: state
      }
    }).then((userInfo) => {
      this.loginUser(userInfo.data.body.userId)
    }).catch(function (msg) {
      alert('获取用户信息失败！')
    })
  },
  mounted() {
    document.title = '商家入驻'; // 设置页面标题
  }
}
</script>
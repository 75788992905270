import { createStore } from 'vuex'

export default createStore({
  state: {
    appId: 'wx64a7b050f7894719',
    appSecret: '343969faf138b149617a5285a6fc56f1',
    accessToken: [],
    weChartDomain: 'https://open.weixin.qq.com',
    host: 'https://tt-server.fjtui.cn/',
    webHost: 'https://tt.fjtui.cn/',
    userInfo: null,
    imgHost: 'http://tt-oa.jiujintui.cn'
  },
  getters: {
  },
  mutations: {
    saveAccessToken (state, accessToken) {
      state.accessToken = accessToken
    }
  },
  actions: {
  },
  modules: {
  }
})

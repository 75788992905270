<template>
  <div class="apply-withdrawal">
    <div class="center">
    <div class="apply-box">
      <div class="title">
        提现金额
      </div>
      <div class="input">
        <span class="logo">¥</span>
        <input type="number" pattern="/^(\d+)?(\.\d{1,2})?$/"
               onkeyup= "if(!/^(\d+)?(\.\d{1,2})?$/.test(this.value)){this.value = parseFloat(this.value).toFixed(2)}"
               title="请输入正确的价格格式" placeholder="请输入提现金额" v-model="money" name="money" id="money">
        <span class="all" @click="allBalance">全部提现</span>
      </div>
      <div class="balance">
        <span class="balance-title">可提现金额</span>
        <span class="money">¥&nbsp;<span v-text="balance"></span></span>
        <a class="record-link" href="/withdrawal/record">提现记录</a>
      </div>
    </div>
    </div>
    <div class="center">
      <div class="msg">
        <div class="qr">
          <img :src="imgSrc">
          <img v-if="imgSrc === null" src="../assets/loading.gif">
        </div>
        <div class="text">
          <span>请长按识别二维码添加财务可服务微信办理提现，添加成功后请提供注册手机号，并发送指令:"我要提现"，即可提现！</span>
        </div>
      </div>
      <button class="submitBtn" @click="apply">申请提现</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      balance: '0.00',
      money: '',
      userInfo: null,
      imgSrc: null
    }
  },
  methods: {
    allBalance() {
      this.money = this.balance
    },
    setUserInfo(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        if (this.userInfo === null || this.userInfo.status !== 1) {
          this.$router.push({name : 'expertRegistration'});
          return;
        }

        sessionStorage.setItem('user', JSON.stringify(this.userInfo))
      }).catch(function (msg) {
        alert('获取用户数据失败！')
        console.log(msg)
        this.$router.push({name : 'businessSettleFlow'})
      })
    },
    apply() {
      this.money = document.getElementById('money').value;
      if (this.money === '' || this.money < 0.01) {
        alert('请输入正确的金额')
        return;
      }

      if (parseFloat(this.money) > parseFloat(this.balance)) {
        alert('余额不足！')
        return;
      }

      let params = {
        "userId": this.userInfo.id,
        "withdrawalAmount": this.money
      }
      axios.post(this.$store.state.host + 'tt/wechat/account/withdrawal/apply', params
      ).then((msg) => {
        alert('提现成功！')
        this.$router.push({name: 'personalCenter'})
      }).catch(function (msg) {
        console.log(msg)
        alert(msg.data.msg)
      })
    }
  },
  mounted() {
    document.title = '申请提现'; // 设置页面标题
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (this.userInfo === null) {
      this.$router.push({name : 'expertRegistration'})
    }
    this.setUserInfo(this.userInfo.id);
    this.balance = this.userInfo.accountUserInfoResponse.availableForWithdrawalAmount ? this.userInfo.accountUserInfoResponse.availableForWithdrawalAmount : '0.00'


    axios.get(this.$store.state.host + 'tt/wechat/common/config/images'
    ).then((result) => {
      let data = result.data.body;
      data.forEach(item => {
        if (item.name === "withdrawal") {
          this.imgSrc = this.$store.state.imgHost + item.value;
          console.log(this.imgSrc)
        }
      })
    })
  }
}
</script>

<style>
.apply-withdrawal .submitBtn {
  width: 90%;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: rgb(73, 101, 242);
  margin-top: 40px;
}
.apply-withdrawal .msg {
  align-items: center;
  width: 90%;
  border-radius: 10px;
  padding: 30px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 40px;
  background-color: rgb(231, 232, 236);
}
.apply-withdrawal .msg .text {
  color: rgb(125, 128, 135);
  width: 70%;
  text-align: left;
}

.apply-withdrawal .qr img {
  width: 50px;
}
.apply-withdrawal .apply-box {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80%;
  margin-top: 20px;
  border-radius: 10px;
  background-color: white;
}

.apply-withdrawal .balance {
  text-align: left;
  margin-top: 20px;
  font-size: 18px;
  width: 100%;
}

.apply-withdrawal .balance .money {
  color: #498ff2;
  padding-left: 10px;
}

.apply-withdrawal .balance .record-link {
  text-decoration: none;
  color: #498ff2;
  float: right;
}
.apply-withdrawal .input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #888888;
  width: 100%;
}

.apply-withdrawal .input .logo {
  font-size: 30px;
}

.apply-withdrawal .input .all {
  padding-top: 5px;
  color: #498ff2;
  font-size: 20px;
  text-align: left;
}

.apply-withdrawal .input input {
  margin-left: 5px;
  width: 55%;
  font-size: 22px;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 200;
}

.apply-withdrawal .title {
  font-size: 28px;
  text-align: left;
}
</style>
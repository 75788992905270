<template>
  <div class="payment">

    <div class="center">
      <div class="user-message box">
        <div class="avatar">
          <img :src=this.userInfo.avatar>
        </div>
        <div class="user-message-box">
          <span class="nickname" v-text="userInfo.nickName"></span>
          <span class="phone" v-text="userInfo.phone"></span>
        </div>
      </div>

<!--      //选择套餐标准-->
      <div v-for="item in setMenus" :key="item.id" :class="item.id === clickedMenu.id ? 'set-menu  click' : 'set-menu'" @click="clickSetMenu(item)" :id="'set-menu-' + item.id">
        <div class="align-items">
          <div class="align-items">
            <img v-if="item.headUrl" :src="item.headUrl">
            {{item.title}}
          </div>
          <div style="color:#999;">
            X1
          </div>
        </div>
        <div class="price">
          <span style="padding-right: 10px;">服务费:</span>
          <span style="color:red;" v-text="'¥' + item.originalPrice"></span>
        </div>
        <div class="commission">
          <span style="padding-right: 10px;">商户抽佣比例:</span>
          <span v-text="item.saleF"></span>
        </div>
      </div>

      <div class="describe">
        <span>
        服务包含：外卖开通 、上线套餐、数据达标检测、小票打印设置、对接骑手平台、餐品指导设计、营销活动指导、外卖顾客维护、门店数据反馈、经营维护、定期培训赋能、专属客服。
        </span>
      </div>

      <div class="box coupon" v-if="clickedMenu.settledPreferential > 0">
        <div class="tip-box">
          <div class="tip">限时省</div>
          <span style="color: #888888;padding-left: 10px;">优惠券</span>
        </div>
        <div style="font-family: ui-serif;">
          (<span v-text="'-¥' + clickedMenu.settledPreferential" style="color: red;"></span>)渠道推荐优惠
        </div>
        <div style="width: 100%;text-align: right;">
          费用总计:&nbsp;&nbsp;
          <span v-text="'¥' + clickedMenu.preferentialPrice" style="color:#986530;"></span>
        </div>
      </div>
      <div class="term">
        <input class="checkbox" type="checkbox" @click="checkTerm" id="term" name="term">
        <span class="message">我已阅读并同意
          <a href="/agreement?fileName=ProductOrderingAndServiceAgreement">《天天外卖产品订购及服务协议》</a>
        </span>
      </div>
    </div>

    <div class="bottom">
      <div>
        <div>
          <span style="padding-top: 5px;color: black;">实际支付:</span>
          <span class="money">{{clickedMenu.preferentialPrice}}</span>
        </div>
        <div v-if="clickedMenu.settledPreferential > 0" style="color: #777;font-size: 14px;font-weight: 300">
          (渠道减免:{{clickedMenu.settledPreferential}}元)
        </div>
      </div>
      <button @click="pay" class="btn">支付</button>
    </div>
  </div>
</template>

<style>

.payment .describe {
  font-size: 14px;
  color: #aaaaaa;
  width: 85%;
  text-align: left;
  margin-bottom: 10px;
  border-radius: 7px;
}

.payment .set-menu img {
  width: 35px;
  height: 35px;
}
.payment .set-menu .price {
  font-size: 22px;
  text-align: right;
}
.payment .set-menu {
  border: 2px solid white;
  font-size: 18px;
  width: 90%;
  padding: 10px 20px 10px 20px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 7px;
}

.payment .set-menu .commission {
  text-align: right;
  font-size: 12px;
}

.payment .click {
  border: 2px solid #4965f2;
}

.payment {
  font-weight: 600;
  padding-bottom: 200px;
  overflow: hidden;
  background-color: rgb(240, 240, 240);
  min-height: 100vh; /* 使div的高度为视口高度 */
  width: 100vw; /* 使div的宽度为视口宽度 */
}
.payment .box {
  width: 90%;
  padding: 30px 20px 20px 20px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 7px;
}

.payment .coupon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.payment .coupon .tip-box {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.payment .coupon .tip {
  color: white;
  background-color: red;
  padding: 7px 5px 7px 5px;
  font-size: 12px;
  border-radius: 5px;
}

.payment .content img {
  margin-top: -10px;
}

.payment .user-message .user-message-box {
  display: grid;
  text-align: left;
  margin-left: 15px;
}
.payment .user-message {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.payment .user-message img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: left;
}
.payment .user-message .nickname {
  color: black;
  font-weight: bold;
  font-size: 18px;
}
.payment .user-message .phone {
  color: #999999;
  font-family: monospace;
}

.payment .bottom {
  background-color: white;
  position: fixed;
  bottom: 0;
  align-items: center;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: red;
}

.payment .bottom .money {
  font-size: 18px;
}

.payment .bottom .btn {
  background-color: red;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px;
  width: 130px;
  font-size: 16px;
}

.payment .term {
  text-align: left;
  width: 90%;
  margin-top: 20px;
}
.payment .message {
  font-size: 12px;
  color: #a3a3a3;
  padding-left: 5px;
  padding-top: 1px;
}
.payment .message a {
  color: black;
}
.payment .checkbox {
  background-color: #ababab;
}
</style>

<script>
import wx from "weixin-js-sdk";
import axios from "axios";

export default {
  name: "expert_registration",
  data() {
    return {
      showInviter: false,
      inviterId: 0,
      inviter: null,
      userInfo: {
        avatar: '',
        nickName: '',
      },
      res: '',
      clickedMenu: [],
      setMenus: [],
      hasDiscount: false
    }
  },
  mounted() {
    document.title = '确认订单';
  },
  methods: {
    clickSetMenu(item) {
      document.getElementById('set-menu-' + this.clickedMenu.id).classList.remove('click');
      this.clickedMenu = item;
      document.getElementById('set-menu-' + this.clickedMenu.id).classList.add('click');
    },
    checkTerm() {
      let term = document.getElementById('term');
      if (term.checked) {
        term.setCustomValidity('');
      }
    },
    pay() {
      let term = document.getElementById('term');
      if (!term.checked) {
        alert('请阅读并同意相关服务事项');
        return false;
      }

      if (this.clickedMenu === []) {
        alert('请先选择相关服务！')
        return false;
      }

      let params = {
        userId: this.userInfo.id,
        payId: this.clickedMenu.id
      }

      if (this.inviterId > 0) {
        params = {
          userId: this.userInfo.id,
          payId: this.clickedMenu.id,
          commissionUserId: this.inviterId
        }
      }

      axios.post(this.$store.state.host + 'tt/wechat/order/pay', params).then((res) => {
        this.weixinPay(res.data.body);
      }).catch(function (msg) {
        alert(msg.data.msg);
      })
    },
    weixinPay(data) {
      sessionStorage.setItem('orderId', data.orderId);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.wxPayMpOrderResult.appId, // 必填，公众号的唯一标识
        timestamp: data.wxPayMpOrderResult.timeStamp, // 必填，生成签名的时间戳
        nonceStr: data.wxPayMpOrderResult.nonceStr, // 必填，生成签名的随机串
        signature: '', // 必填，
        jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      wx.ready(function() {
        wx.chooseWXPay({
          timestamp: data.wxPayMpOrderResult.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: data.wxPayMpOrderResult.nonceStr, // 支付签名随机串，不长于 32 位
          package: data.wxPayMpOrderResult.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
          signType: data.wxPayMpOrderResult.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: data.wxPayMpOrderResult.paySign, // 支付签名
          success: (res) => {
            sessionStorage.setItem('tt_pay', '1');
            window.location.href = '/business/customer';
          },
          fail: (res) => {
            console.log(res)
          },
        });
      });

      wx.error(function(res) {
        this.res = res;
      });
    },
    setUserInfo(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        this.checkUserStatus();
        this.setFee();
      }).catch(function (msg) {
        alert(msg)
        this.$router.push({name : 'businessSettleFlow'})
      })
    },
    checkUserStatus() {
      if (this.userInfo === null) {
        this.$router.push({name : 'businessSettleFlow'})
        return;
      }

      if (this.userInfo.payStatus === 1) {
        this.$router.push({name: 'customerView'})
        return;
      }

      if (this.userInfo.merchantStatus === 1) {
        if (this.inviterId > 0) {
          this.$router.push({name: 'businessPayment', query: {inviterId: this.inviterId}})
        } else {
          this.$router.push({name: 'businessPayment'})
        }
      }
    },
    setFee() {
      axios.get(this.$store.state.host + 'tt/wechat/merchant/service/fee', {
        params: {
          userId: this.userInfo.id,
          commissionUserId: this.inviterId > 0 ? this.inviterId : 0
        }
      }).then((fee) => {
        this.setMenus = fee.data.body
        this.clickedMenu = this.setMenus[0]
      }).catch(function (msg) {
        console.log(msg)
        alert('获取购买信息失败！')
      })
    }
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem('user'));

    if (sessionStorage.getItem('tt_pay')) {
      this.$router.push({name : 'businessSettleFlow'})
      return;
    }

    this.setUserInfo(user.id)
    this.inviterId = this.$route.query.inviterId
  }
}
</script>

<template>
  <div class="about">
    <div v-html="htmlContent"></div>
  </div>
</template>

<style>
.about {
  padding: 10px;
}
</style>
<script>
export default {
  data() {
    return {
      fileName: '',
      htmlContent: ''
    };
  },
  created() {
    this.fileName = this.$route.query.fileName
    if (this.fileName) {
      console.log(this.name)
      this.htmlContent = "";

      fetch('/' + this.fileName + '.html')
          .then(response => response.text())
          .then(html => {
            this.htmlContent = html;
          }).catch(error => {
        console.error('Error fetching HTML content:', error);
      });
    }
  }
};
</script>
<template>
  <div class="detailed-list">
    <div v-for="record in items" :key="record.id" class="detail-box">
      <div class="box">
        <div class="img">
          <img :src="this.userInfo.avatar">
        </div>
        <div class="text">
          <span class="name" v-if="record.incomeType in [1, 2, 3]" v-text="record.nickName"></span><span v-text="incomeTypes[record.incomeType]"></span><br>
          <span class="msg" v-text="record.registerDate"></span>
        </div>
        <div class="money">
          <span>¥<span v-text="record.commissionAmount"></span></span>
        </div>
      </div>
    </div>

    <div v-if="!hasData" style="margin-top: 10px;">暂无数据</div>
    <div ref="observerElement"></div>
  </div>
</template>

<style>

body {
  margin: 0;
}
.detailed-list {
  min-height: 100%;
  background-color: white;
  padding: 10px 25px 10px 25px;
}
.detailed-list .detail-box .money {
  font-size: 22px;
  padding: 10px;
  width: 30%;
  text-align: right;
}
.detailed-list .detail-box .box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px #888888 solid;
  padding-top: 10px;
  padding-bottom: 10px;
}
.detailed-list .detail-box img {
  width: 50px;
}
.detailed-list .text {
  width: 50%;
  padding-left: 5px;
  padding-top: 5px;
  text-align: left;
}
.detailed-list .text .name {
  font-size: 16px;
  font-weight: bold;
}

.detailed-list .text .msg {
  font-size: 12px;
  color: #aaaaaa;
}
</style>

<script>
import axios from "axios";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useStore} from "vuex";

export default {
  setup() {
    let hasData = ref(false);
    const items = ref([]); // 列表数据
    const observerElement = ref(null); // 用于触发加载的元素

    const userInfo = JSON.parse(sessionStorage.getItem('user'));
    if (userInfo === null) {
      return {
        items,
        hasData,
        observerElement
      };
    }

    const observer = ref(null); // IntersectionObserver 实例
    let page = 1; // 初始化页码
    let pageSize = 20;
    let page_total = 1;
    const store = useStore();

    // 加载更多内容的函数
    const loadMoreItems = () => {
      // 模拟异步加载数据
      if (page > page_total) {
        return;
      }

      axios.get(store.state.host + 'tt/wechat/user/income/page', {
        params: {
          pageIndex: page,
          pageSize: pageSize,
          condition: userInfo.id
        }
      }).then((withdrawalRecords) => {
        if (withdrawalRecords.data.body.data.length > 0) {
          items.value = [...items.value, ...withdrawalRecords.data.body.data];
          hasData.value = true;
          page++; // 页码递增
        }
        page_total = withdrawalRecords.data.body.page.page_total;
      })
    };

    // 创建 IntersectionObserver 实例
    onMounted(() => {
      observer.value = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      }, {
        root: null, // 视口作为根元素
        rootMargin: '0px',
        threshold: 1.0 // 完全可见时触发
      });

      if (observerElement.value) {
        observer.value.observe(observerElement.value);
      }
    });

    // 停止观察并断开 IntersectionObserver 实例
    onBeforeUnmount(() => {
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    return {
      items,
      hasData,
      observerElement
    };
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
  },
  data() {
    return {
      userInfo: null,
      records: null,
      incomeTypes: {
        1: '推广达人',
        2: '推广商家',
        3: '取消入驻',
        4: '订单收益'
      }
    }
  },
  mounted() {
    document.title = '收益明细'; // 设置页面标题
  }
}
</script>
<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link>-->
<!--    <router-link to="/about">About</router-link>｜-->
<!--    <router-link to="/wechartLogin">微信登陆</router-link>-->
<!--  </nav>-->
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

body {
  background-color: rgb(240, 240, 240);
}

.inviter {
  height: 40px;
  background-color: rgba(230, 230, 230);
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.inviter img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.inviter .userInfo {
  font-size: 14px;
  padding-left: 5px;
}
.term {
  display: flex;
  align-items: center;
}

/* 阅读条款按钮 */
.term .checkbox {
  vertical-align: bottom;
  appearance:none;/*取消默认的复选框样式*/
  /*重新写一个复选框圆角样式*/
  width:14px;/*设置方形宽度为20px*/
  height:14px;/*设置方形高度为20px*/
}
.term .checkbox:checked {
  background-image:url('./assets/checked.png');/*复选框选中后添加背景图片*/
  background-repeat:no-repeat;/*取消背景图片平铺*/
  background-size:20px 20px;/*背景图片大小,第一个27px表示width宽度,第二个27px表示height高度*/
  background-position: center;/*背景图居中对齐*/
  background-color:rgb(73, 143, 242);
}

.term a {
  text-decoration: none;
}

.center {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.align-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<script setup lang="ts">
</script>
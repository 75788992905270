<template>
  <div class="sage-poster">
    <div class="user-show">
      <div class="avatar">
        <img :src="this.userInfo.avatar">
      </div>
      <div class="user-message">
        <div class="tip-box">
          <span class="name" v-text="userInfo.nickName"></span>
          <div v-if="userInfo.agentLevel === 1" class="tip">
            <img src="../assets/personal/level2.png">
            达人
          </div>

          <div v-if="userInfo.agentLevel === 2" class="tip">
            <img src="../assets/personal/level2.png">
            大达人
          </div>
        </div>

        <div class="count">
          <span>我的商家:</span>
          <span style="padding-left: 5px;color: #498ff2;">{{sageCount}}</span>
        </div>
      </div>
    </div>

    <div class="sage-content">
      <div class="describe">
        <span>邀请好友注册，轻松赚佣金</span>
        <span>成功邀请20人注册，收入轻松翻倍</span>
      </div>

      <div class="" v-if="imageUrl">
        <img :src=imageUrl>
        <div class="">
          长按图片保存达人邀请码
        </div>
<!--        <div class="btn-box">-->
<!--          <button class="btn share" @click="share">立即邀请</button>-->
<!--          <button class="btn save" @click="saveImage">保存邀请码</button>-->
<!--        </div>-->
        <div @keyup.enter="handleEnterPress">
          <!-- 你的组件内容 -->
        </div>
      </div>

      <div v-if="imageUrl === null">
        <img src="../assets/loading.gif" style="width: 50px;margin-top: 50px">
      </div>
    </div>
  </div>
</template>

<style>
.sage-poster .sage-content {
  padding-bottom: 20px;
}

.sage-poster .count {
  font-size: 12px;
  color: #666;
}

.sage-poster .tip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  margin-right: 10px;
  border-radius: 5px;
  border: 2px solid #dedede;
  font-size: 10px;
  color: #7d8087;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 10px;
}

.sage-poster .tip-box {
  display: flex;
  align-items: center;
}

.sage-poster .btn-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;
}

.sage-poster .btn-box .share {
  background-color: #3dcf84;
}
.sage-poster .btn-box .save {
  background-color: #fe9730;
}
.sage-poster .btn-box .btn {
  color: white;
  border: none;
  padding: 10px;
  width: 40%;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
}

.sage-poster .user-show .name {
  font-size: 16px;
  font-weight: bold;
}
.sage-poster .user-show .phone {
  font-size: 14px;
  color: #888888;
}
.sage-poster .sage-content img {
  width: 70%;
  border-radius: 5px;
}

.sage-poster .describe {
  padding: 20px 0 20px 0;
  font-weight: 600;
  display: grid;
  justify-content: center;
}

.sage-poster .user-show {
  padding: 20px 10px 20px 10px;
  background-color: #f0f0f0;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.sage-poster .user-show .user-message {
  display: grid;
  text-align: left;
  padding-left: 10px;
}

.sage-poster .user-show .avatar img {
  width: 50px;
  border-radius: 50%;
  margin-left: 40px;
}

</style>

<script>
import axios from "axios";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      imageUrl: null,
      userInfo: null,
      type: 0,
      sageCount: 0
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));

    this.setImg()
    axios.get(this.$store.state.host + 'tt/wechat/user/promote/statistics', {
      params: {
        type: this.type,
        userId: this.userInfo.id
      }
    }).then((result) => {
      this.businessCount = result.data.body.promoteMNum ?? 0
    })
  },
  mounted() {
    document.title = '达人邀请码'; // 设置页面标题
  },
  methods: {
    setImg() {
      axios.get(this.$store.state.host + 'tt/wechat/user/generate/promoter', {
        params: {
          userId: this.userInfo.id
        }
      }).then((img) => {
        this.imageUrl = img.data.body
      })
    },
    saveImage() {
      if (this.imageUrl === null) {
        return;
      }

      const link = document.createElement('a');
      link.href = this.imageUrl;
      link.setAttribute('download', '达人邀请码.jpg'); // 可以设置下载文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    share() {
      axios.get(this.$store.state.host + 'tt/wechat/user/share/sign', {
        params: {
          type: this.type,
          userId: this.userInfo.id
        }
      }).then((result) => {
        this.weixinShare(result.data.body)
      })
    },
    weixinShare(data) {
      wx.config({
        debug: true,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature,// 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      });

      wx.updateAppMessageShareData({
        title: '邀请你入驻达人', // 分享标题
        imgUrl: this.imageUrl, // 分享图片
        link: this.$store.state.webHost + 'expert/registration?userId=' + this.userInfo.id,
        success: function () {
          // 设置成功
          alert('分享成功')
        },
        cancel: function () {
          // 用户取消分享
          alert('分享取消')
        }
      });
    }
  }
}
</script>
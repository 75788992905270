<template>
  <div class="registration">
    <div v-if="showInviter" class="inviter">
      <div class="avatar">
        <img :src=inviter.avatar>
      </div>
      <div class="userInfo">
        <span>{{this.inviter.nickName}}&nbsp;&nbsp;邀请你入驻</span>
      </div>
    </div>
    <h1 style="text-align: left;margin-left: 5%;margin-top: 50px">入驻短视频外卖</h1>
    <form class="registerFrom" @submit.prevent="register">
      <div class="text">
        <input class="input" id="title" type="text" maxlength="50" v-model="formData.merchantName" placeholder="请输入商户名称" required>
      </div>

      <div class="text">
        <input class="input" id="phone" type="tel" pattern="^1\d{10}$" v-model="formData.phone" placeholder="请输入手机号" required>
      </div>

      <div class="verificationCodeInput">
        <input class="verificationCodeInput" type="tel" maxlength="6" v-model="formData.verificationCode" placeholder="请输入短信验证码" required>
        <button id="sendCode" type="button" class="sendCodeBtn" @click="sendVerificationCode">
          {{ clockShow ? clock + 's后重新获取验证码' : '获取验证码' }}
        </button>
      </div>

      <div class="term">
        <input class="checkbox" type="checkbox" @click="checkTerm" id="term" name="term">
        <span class="message">我已阅读并同意
          <a href="/agreement?fileName=MerchantVersionUserServiceAgreement">《商家版用户服务协议》</a>
          和<a href="/agreement?fileName=PrivacyPolicySummary">《隐私政策》</a>
        </span>
      </div>

      <center>
        <button class="submitBtn" type="submit">注册商户</button>
      </center>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "expert_registration",
  data() {
    return {
      userId: 0,
      redirectUri: this.$store.state.host,
      weChartDomain: this.$store.state.weChartDomain,
      formData: {
        merchantName: '',
        phone: '',
        verificationCode: '',
      },
      clock: 60,
      showInviter: false,
      clockShow: false,
      inviterId: 0,
      inviter: null,
      userInfo: null
    }
  },
  created() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    this.setUserInfo(user.id)
    this.setInviter();
  },
  methods: {
    sendVerificationCode() {
      var phone = document.getElementById('phone');
      if (phone.value === "") {
        phone.setCustomValidity('请输入手机号')
        return;
      }

      const regex = /^[1][3-9]\d{9}$/; // 以1开头，第二位是3-9，后面跟着9位数字
      if (!regex.test(phone.value)) {
        phone.setCustomValidity('手机号不正确')
        return;
      }

      phone.setCustomValidity('')

      if (!this.clockShow) {
        axios.get(this.$store.state.host + 'tt/wechat/user/verify/code', {
          params: {
            phone: this.formData.phone
          }
        }).then((msg) => {
          //触发倒计时
          this.setTime()
          this.clockShow = true
          console.log(msg)
        }).catch(function (msg) {
          console.log(msg)
        })
      }
    },
    setTime() {
      if (this.clock === 0) {
        this.clock = 60
        this.clockShow = false
        document.getElementById('sendCode').classList.remove('clicked')
        return
      } else {
        this.clock--
      }
      setTimeout(() => { this.setTime() }, 1000)
    },
    register() {
      let term = document.getElementById('term');
      if (!term.checked) {
        alert('请阅读并同意相关服务事项');
        return false;
      }

      let params = {
        userId: this.userInfo.id,
        phone: this.formData.phone,
        merchantName: this.formData.merchantName,
        verifyCode: this.formData.verificationCode
      }

      axios.post(this.$store.state.host + 'tt/wechat/merchant/modify', params).then((res) => {
        if (res.data.code !== '200') {
          alert(res.data.msg)
          return;
        }

        if (this.inviterId > 0) {
          this.$router.push({name: 'businessPayment', query: {inviterId: this.inviterId}})
        } else {
          this.$router.push({name: 'businessPayment'})
        }
      }).catch(function (error) {
        alert(error.data.msg)
      });
    },
    setUserInfo(userId) {
      axios.get(this.$store.state.host + 'tt/wechat/user/info', {
        params: {
          userId: userId
        }
      }).then((userInfo) => {
        this.userInfo = userInfo.data.body;
        this.checkUserStatus();
      }).catch(function (msg) {
        alert(msg)
        this.$router.push({name : 'businessSettleFlow'})
      })
    },
    checkUserStatus() {
      if (this.userInfo === null) {
        this.$router.push({name : 'businessSettleFlow'})
        return;
      }

      if (this.userInfo.payStatus === 1) {
        this.$router.push({name: 'customerView'})
        return;
      }

      if (this.userInfo.merchantStatus === 1) {
        if (this.inviterId > 0) {
          this.$router.push({name: 'businessPayment', query: {inviterId: this.inviterId}})
        } else {
          this.$router.push({name: 'businessPayment'})
        }
      }
    },
    setInviter() {
      this.inviterId = this.$route.query.inviterId
      if (this.inviterId > 0) {
        axios.get(this.$store.state.host + 'tt/wechat/user/info', {
          params: {
            userId: this.inviterId
          }
        }).then((inviter) => {
          this.showInviter = true;
          this.inviter = inviter.data.body;
        })
      }
    }
  },
  mounted() {
    document.title = '商家注册'; // 设置页面标题
  }
}
</script>

<style>
.registration .registerFrom input {
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
}

.registration .registerFrom input::placeholder {
  color: #c3c3c3;
}

.registration .registerFrom .text {
  padding-top: 10px;
  padding-bottom: 10px;
}
.registration .registerFrom .text .input {
  height: 37px;
  font-size: 12px;
  width: 90%;
  padding: 5px;
}
.registration .registerFrom .verificationCodeInput {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.registration .registerFrom .verificationCodeInput input {
  width: 50%;
  height: 37px;
  padding: 5px;
  font-size: 12px;
  background-color: #f0f0f0;
}
.registration .registerFrom .verificationCodeInput .sendCodeBtn {
  height: 37px;
  font-size: 12px;
  width: 40%;
  color: #a8a8a8;
  border: none;
  padding: 5px;
}

.registration .registerFrom .submitBtn {
  margin-top: 5px;
  width: 90%;
  color: white;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: rgb(73, 101, 242);

  /*top: 70%;*/
  /*left: 8%;*/
  /*position: fixed;*/
}

.registration .registerFrom .term {
  text-align: left;
  margin-top: 20px;
  margin-left: 5%;
}
.registration .registerFrom .message {
  font-size: 12px;
  padding-left: 5px;
  color: #a3a3a3;
}
.registration .registerFrom .message a {
  color: black;
}
</style>
<template>
  <div class="">
    <img :src="imgSrc" style="width: 100%;">
    <img v-if="imgSrc === null" src="../assets/loading.gif">
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      imgSrc: null
    }
  },
  created() {
    axios.get(this.$store.state.host + 'tt/wechat/common/config/images'
    ).then((result) => {
      let data = result.data.body;
      data.forEach(item => {
        if (item.name === "learn-center") {
          this.imgSrc = this.$store.state.imgHost + item.value;
        }
      })
    })
  }
}
</script>

<style>

</style>
<template>
  <div class="qualification-info">

    <form class="center content">
      <div class="content-box qualification">
        <div class="box-title">
          <span>资质信息</span>
        </div>

        <div class="license-code">
          <div class="title"><span class="required">*</span>执照编码</div>
          <input type="text" v-model="lcode" maxlength="50" v-if="canModify" placeholder="请输入您的执照编码" id="license-code">
          <input type="text" v-model="lcode" maxlength="50" v-if="!canModify" readonly="readonly" placeholder="请输入您的执照编码" id="license-code">
        </div>
      </div>

      <div class="content-box">
        <div class="certificate-img">
          <div class="title">
            <span class="required">*</span>
            <span>证件类型</span>
            <span style="padding-left: 10px;">营业执照</span>
          </div>

          <img v-if="blImgSrc == null" @click="triggerFileUpload(4)" src="../../../assets/merchant-info/certificate.png" />
          <img v-if="blImgSrc != null" @click="triggerFileUpload(4)" :src="blImgSrc" />
        </div>


        <div class="certificate-img permit">
          <div class="title">
            <span class="required">*</span>
            <span>证件类型</span>
            <span style="padding-left: 10px;">食品经营许可证</span>
          </div>

          <img v-if="fblImg == null" @click="triggerFileUpload(5)" src="../../../assets/merchant-info/permit.png" />
          <img v-if="fblImg != null" @click="triggerFileUpload(5)" :src="fblImg" />
        </div>
      </div>
    </form>

    <div class="">
      <input ref="imgInput" class="img-upload-btn hidden" type="file" id="prompt-img-btn" accept="image/*">
    </div>

    <div class="center">
      <div class="bottom-btn">
        <button class="previous-step" @click="previousStep">上一步</button>
        <button class="next-btn" @click="nextInfo">下一步</button>
      </div>
    </div>
  </div>
</template>

<style>
.qualification-info .permit {
  margin-top: 40px;
}

.qualification-info .content-box img {
  width: 70%;
  margin-top: 20px;
}

.qualification-info .certificate-img .title {
  text-align: left;
}

.qualification-info .center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.qualification-info .previous-step {
  width: 30%;
  margin-top: 20px;
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
}

.qualification-info .next-btn {
  width: 60%;
  padding: 15px 20px 15px 20px;
  margin-top: 20px;
  color: white;
  background-color: #3579f6;
  border: 1px solid #3579f6;
  border-radius: 5px;
}

.qualification-info .bottom-btn {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.qualification-info .required {
  color: #D40000;
  padding-right: 5px;
}

.qualification-info .license-code {
  text-align: left;
  margin-top: 20px;
  border-bottom: 1px solid #F4F4F4;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.qualification-info .content input {
  margin-left: 5px;
  width: 70%;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 450;
}

.qualification-info .content input::placeholder {
  text-align: right;
  font-weight: 200;
}

.qualification-info {
  min-height: 100vh; /* 使div的高度为视口高度 */
  width: 100vw; /* 使div的宽度为视口宽度 */
  padding-bottom: 50px;
  background-color: #f0f0f0;
}

.qualification-info .content .content-box {
  width: 90%;
  min-height: 100px;
  background-color: white;
  padding: 20px 10px 20px 10px;
  margin-top: 20px;
  border-radius: 5px;
}

.qualification-info .content .box-title {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      userInfo: null,
      lcode: '',
      blImgSrc: null,
      fblImg: null,
      imgType: 0,
      canModify: false,
    }
  },
  mounted() {
    this.$refs.imgInput.addEventListener('change', this.uploadImage);
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    this.getMerchantInfo();
  },
  methods: {
    previousStep() {
      this.$router.push({name: 'merchantStoreInfo'});
    },
    uploadImage(event) {
      const file = event.target.files[0];
      if (!file) {
        console.log('No file selected');
        return;
      }
      var fileSize = file.size; // 获取文件大小（字节）
      var maxSize = 5 * 1024 * 1024; // 设置最大文件大小（这里设置为1MB）
      if (fileSize > maxSize) {
        alert('图片大小不能超过5MB！');
        return false;
      }

      axios.post(this.$store.state.host + 'tt/wechat/common/merchant/img', {
        file: file,
        type: this.imgType,
        uid: this.userInfo.id
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        alert('上传成功！')
        if (this.imgType === 4) {
          this.blImgSrc = response.data.body.url;
        } else if (this.imgType === 5) {
          this.fblImg = response.data.body.url;
        }

      }).catch(function () {
        alert('上传图片失败！')
      });
    },
    triggerFileUpload(type) {
      if (!this.canModify) {
        return;
      }

      this.imgType = type
      this.$refs.imgInput.click();
    },
    nextInfo() {
      if (!this.canModify) {
        this.$router.push({name: 'merchantInfo'});
        return;
      }

      if (this.lcode === '') {
        alert('请输入执照编码');
        return;
      }

      if (this.blImgSrc === null) {
        alert('请上传营业执照');
        return;
      }

      if (this.fblImg === null) {
        alert('请上传食品经营许可证');
        return;
      }

      let formData = {
        uid: this.userInfo.id,
        lcode: this.lcode,
        blImg: 4,
        fblImg: 5
      }

      axios.post(this.$store.state.host + 'tt/wechat/merchant/onboarding/credential', formData
      ).then(response => {
        this.$router.push({name: 'merchantInfo'});
      }).catch(function (error) {
        alert(error.body.msg)
      });

    },
    getMerchantInfo() {
      axios.get(this.$store.state.host + 'tt/wechat/merchant/info', {
        params: {
          userId: this.userInfo.id
        }
      }).then(response => {

        let merchantInfo = response.data.body;
        this.blImgSrc = merchantInfo.credentialCmd.blImg;
        this.fblImg = merchantInfo.credentialCmd.fblImg;
        this.lcode = merchantInfo.credentialCmd.lcode;

        if (merchantInfo.status !== 4) {
          this.canModify = true;
        }

      }).catch(function (error) {
        alert(error.body.msg)
      });
    }
  }
}
</script>
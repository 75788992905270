<template>
  <div class="merchant-info">
    <form class="center content">
      <div class="content-box qualification">
        <div class="box-title">
          <span>商户信息填写</span>
        </div>

        <div class="placeholder-right input-msg">
          <div class="title"><span class="required">*</span>法人姓名</div>
          <input type="text" maxlength="50" v-model="lpname" v-if="canModify" placeholder="请输入法人姓名">
          <input type="text" maxlength="50" v-model="lpname" v-if="!canModify" readonly="readonly" placeholder="请输入法人姓名">
        </div>

        <div class="placeholder-right input-msg">
          <div class="title"><span class="required">*</span>法人手机号</div>
          <input type="tel" pattern="^1\d{10}$" v-model="lpphone" v-if="canModify" placeholder="请输入法人手机号" id="phone">
          <input type="tel" pattern="^1\d{10}$" v-model="lpphone" v-if="!canModify" readonly="readonly" placeholder="请输入法人手机号" id="phone">
        </div>

        <div class="input-msg">
          <div class="title"><span class="required">*</span>发送验证码</div>
          <input type="text" style="width: 30%;" maxlength="50" placeholder="请输验证码" v-model="vcode">
          <button type="button" class="send-code" @click="sendVerificationCode">{{ clockShow ? clock + 's后重新获取验证码' : '获取验证码' }}</button>
        </div>

        <div class="input-msg">
          <div class="title" style="padding-left: 10px;">
            门店ID<span style="color: #A1A1A1">（ID查看：登录来客-门店管理-对应店编号）</span>
          </div>
          <input class="store-id" type="text" v-model="lid" maxlength="50" placeholder="请输您的门店ID">
        </div>


      </div>

      <div class="term-box" style="width: 90%;margin-top: 20px;">
        <div class="term" style="text-align: left;align-items: baseline;">
          <input class="checkbox" type="checkbox" id="term" name="term" style="background-color: #aaa;position: absolute;margin-top: 3px;">
          <span class="message">&nbsp;&nbsp;&nbsp;&nbsp;我已阅读并同意
          <a href="#">《POI信息服务协议》</a>
          和<a href="#">《抖音生活服务平台商户合作协议》</a>
          和<a href="#">《团购软件服务费协议》</a>
          </span>
        </div>
      </div>

      <div class="center">
        <div class="bottom-btn">
          <button class="previous-step" @click="previousStep">上一步</button>
          <button class="next-btn" type="button" @click="submitInfo">提交</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style>

.merchant-info .center {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.merchant-info .previous-step {
  width: 30%;
  margin-top: 20px;
  background-color: white;
  border: 1px solid white;
  border-radius: 5px;
}

.merchant-info .next-btn {
  width: 60%;
  padding: 15px 20px 15px 20px;
  margin-top: 20px;
  color: white;
  background-color: #3579f6;
  border: 1px solid #3579f6;
  border-radius: 5px;
}

.merchant-info .bottom-btn {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.merchant-info .store-id {
  margin-top: 5px;
}
.merchant-info .store-id::placeholder {
  font-weight: 200;
}
.merchant-info .send-code {
  width: 30%;
  color: white;
  background-color: #007BFF;
  border: none;
  padding: 5px;
  border-radius: 3px;
}

.merchant-info .placeholder-right input::placeholder {
  text-align: right;
  font-weight: 200;
}

.merchant-info .input-msg input {
  margin-left: 5px;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 450;
}

.merchant-info .placeholder-right input {
  margin-left: 5px;
  width: 70%;
  border: none;
  outline: none;
  height: 30px;
  font-weight: 450;
}

.merchant-info .required {
  color: #D40000;
  padding-right: 5px;
}

.merchant-info .input-msg {
  text-align: left;
  margin-top: 20px;
  border-bottom: 1px solid #F4F4F4;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.merchant-info .content .content-box {
  width: 90%;
  min-height: 100px;
  background-color: white;
  padding: 20px 10px 20px 10px;
  margin-top: 20px;
  border-radius: 5px;
}

.merchant-info .content .box-title {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.merchant-info {
  min-height: 100vh; /* 使div的高度为视口高度 */
  width: 100vw; /* 使div的宽度为视口宽度 */
  padding-bottom: 50px;
  background-color: #f0f0f0;
}
</style>

<script>

import axios from "axios";

export default {
  data() {
    return {
      clock: 60,
      userInfo: null,
      clockShow: false,
      lpname: '',
      lpphone: '',
      vcode: '',
      lid: '',
      canModify: false,
    }
  },
  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user'));
    this.getMerchantInfo();
  },
  methods: {
    getMerchantInfo() {
      axios.get(this.$store.state.host + 'tt/wechat/merchant/info', {
        params: {
          userId: this.userInfo.id
        }
      }).then(response => {

        let merchantInfo = response.data.body;
        this.lpname = merchantInfo.legalCmd.lpname;
        this.lpphone = merchantInfo.legalCmd.lpphone;
        this.lid = merchantInfo.legalCmd.lid;

        if (merchantInfo.status !== 4) {
          this.canModify = true;
        }

      }).catch(function (error) {
        alert(error.body.msg)
      });
    },
    submitInfo() {
      if (!this.canModify) {
        alert('已提交信息不允许修改！');
        return;
      }

      let term = document.getElementById('term');
      if (!term.checked) {
        alert('请阅读并同意相关服务事项');
        return false;
      }

      if (this.lpphone === '') {
        alert('请输入法人姓名');
        return false;
      }

      if (this.lpphone === '') {
        alert('请输入法人手机号');
        return false;
      }

      if (this.vcode === '') {
        alert('请输入验证码');
        return false;
      }

      let params = {
        uid: this.userInfo.id,
        lpname: this.lpname,
        lpphone: this.lpphone,
        vcode: this.vcode,
        lid: this.lid
      }

      axios.post(this.$store.state.host + 'tt/wechat/merchant/onboarding/legal', params
      ).then((response) => {
        if (response.data.code !== '200') {
          alert(response.data.msg);
          return;
        }

        alert('保存成功！')
        this.$router.push({'name': 'customerView'})
      }).catch(function () {
        alert('保存失败！')
      })
    },
    previousStep() {
      this.$router.push({name: 'merchantQualificationInfo'});
    },
    sendVerificationCode() {
      if (!this.canModify) {
        return;
      }

      var phone = document.getElementById('phone');
      if (phone.value === "") {
        alert('请输入手机号');
        return;
      }

      const regex = /^[1][3-9]\d{9}$/; // 以1开头，第二位是3-9，后面跟着9位数字
      if (!regex.test(phone.value)) {
        phone.setCustomValidity('手机号不正确')
        return;
      }

      phone.setCustomValidity('')

      if (!this.clockShow) {
        axios.get(this.$store.state.host + 'tt/wechat/merchant/verify/code', {
          params: {
            phone: phone.value
          }
        }).then((msg) => {
          //触发倒计时
          this.setTime()
          this.clockShow = true
          console.log(msg)
        }).catch(function (msg) {
          console.log(msg)
        })
      }
    },
    setTime() {
      if (this.clock === 0) {
        this.clock = 60
        this.clockShow = false
        return
      } else {
        this.clock--
      }
      setTimeout(() => { this.setTime() }, 1000)
    },
    nextInfo() {
      this.$router.push({name: 'merchantQualificationInfo'});
    }
  }
}
</script>